.defenses-flat-list {
  overflow: scroll;
  height: 75vh;
  margin-bottom: 15vh;
}
.defense-card {
  width: 90vw;
  margin-left: 2vw;
}
.bg-lightgrey {
  background-color: lightgrey;
  width: 15vw;
}
.point-width {
  width: 15vw;
}
.defense-txt-area {
  height: 30vh;
}

.pool-profile-screen {
  height: 83vh;
  background-color: #f5f5f5;
  overflow: scroll;
}
.profile-img-container {
}
.profile-img {
  width: 20vw;
  height: 20vw;
  border-radius: 10vw;
}

.profile-options-container {
}
.profile-option-separator {
  height: 0.15vh;
  background-color: black;
}

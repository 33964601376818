.show-queue-screen {
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}
.show-queue-black-header {
  width: 100vw;
  height: 5vh;
  background-color: black;
}
.queue-car-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}
.queue-bg-grey {
  background-color: rgb(233, 233, 233);
  border: rgb(233, 233, 233);
  border-style: inset;
}

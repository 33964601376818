.transfer-detail {
  overflow: scroll;
  height: 100vh;
  width: 100vw;
}
.transfer-detail-black-header {
  background-color: black;
  height: 15vh;
  width: 100vw;
}
.transfer-detail-screen {
  height: 85vh;
  width: 100vw;
}
.detail-first-box {
  background-color: white;
  height: 21.25vh;
  width: 100vw;
  border-color: lightgrey;
}
.detail-second-box {
  background-color: #f5f5f5;
  height: 21.24vh;
  width: 100vw;
}
.detail-separator {
  height: 0.2vh;
  width: 100vw;
  background-color: white;
}
.btn.detail-go-btn {
  height: 21.24vh;
  width: 20vw;
  border-radius: 0;
}
.detail-third-box {
  background-color: #f5f5f5;
  height: 21.25vh;
  width: 100vw;
}
.detail-forth-box {
  background-color: white;
  height: 21.25vh;
  width: 100vw;
  border-color: lightgrey;
}
.detail-passengers-list {
  overflow: scroll;
  height: 9vh;
}

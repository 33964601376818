.home-screen {
}
.pool-home-header {
  height: 29vh;
}
.home-black-header {
  height: 5vh;
  background-color: black;
}
.home-container {
  background-color: #f5f5f5;
  height: 17.5vh;
}
.home-avatar-container {
}
.home-avatar {
  width: 15vw;
  height: 15vw;
}
.home-avatar-point {
  width: 3vw;
  height: 3vw;
  border-radius: 1.5vw;
  position: absolute;
  margin-left: 13vw;
}
.home-txt-container {
}
.switch-txt {
  font-size: 4.5vw;
  font-weight: 500;
}
.home-name-txt {
  font-size: 4.5vw;
  font-weight: 500;
  color: black;
}
.home-plate-txt {
  font-size: 3.5vw;
  font-weight: 500;
  color: black;
  opacity: 0.5;
}
.car-number-div {
  background-color: #5d78ff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  text-align: center;
}
.car-number-txt {
  color: white;
  font-weight: 600;
}
.day-tab-container {
  background-color: white;
  height: 6.5vh;
}
.day-tab-div {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.day-name-view {
  background-color: #d9e4fc;
  width: 20vw;
  text-align: center;
  border-radius: 0.5vw;
}
.day-name-view-default {
  background-color: white;
  width: 20vw;
  text-align: center;
  border-radius: 0.5vw;
}
.day-name-txt {
  font-size: 4vw;
  font-weight: 500;
  color: #5d78ff;
}
.day-name-txt-default {
  font-size: 4vw;
  font-weight: 500;
  color: grey;
}
.day-count-view {
  background-color: #5d78ff;
  width: 6.25vw;
  height: 6.25vw;
  border-radius: 3.125vw;
  text-align: center;
  z-index: 99;
}
.day-count-view-default {
  background-color: grey;
  width: 6.25vw;
  height: 6.25vw;
  border-radius: 3.125vw;
  text-align: center;
  z-index: 99;
}
.day-count-txt {
  color: white;
  font-weight: 600;
  font-size: 4vw;
}
.day-count-txt-default {
  color: white;
  font-weight: 600;
  font-size: 4vw;
}
.home-render-container {
  width: 85vw;
  height: 14.5vh;
}
.home-render-status-bar {
  width: 1vw;
}
.home-render-transfer-card {
  background-color: white;
  width: 85vw;
}
.home-render-card-top {
  height: 5vh;
}
.card-short-div {
  width: 30vw;
  text-align: center;
}
.card-short-txt {
  font-size: 4.5vw;
}
.card-green-arrow {
}
.home-card-separator {
  height: 0.1vh;
  background-color: black;
  opacity: 0.4;
}
.home-render-card-bottom {
  position: relative;
  height: 8vh;
}
.card-date-div {
  width: 30vw;
}
.card-date-txt {
  font-weight: bold;
  font-size: 3.2vw;
  color: black;
}
.card-hour-txt {
  font-weight: bold;
  font-size: 4.2vw;
  color: black;
}
.card-status-div {
  width: 25vw;
}
.card-status-txt {
  font-weight: bold;
  font-size: 4vw;
  margin-top: 0.5rem;
  line-height: 2vh;
  padding-top: 1rem;
}
.card-grey-arrow {
  padding-bottom: 0.5rem;
  margin-top: -0.2rem;
}
.card-price-div {
  justify-content: center;
  position: absolute;
  margin-left: 60vw;
}
.card-price-img {
  width: 26.5vw;
  height: 6vh;
}
.card-price-txt {
  position: absolute;
  color: white;
  font-weight: 500;
  font-size: 3.2vw;
}
.btn.change-car-btn {
  background-color: #d9e4fc;
  color: #5d78ff;
  font-size: 3vw;
}
.map-switch {
  width: 30vw;
  height: 4vh;
}
.home-map-spinner {
  height: 54vh;
}
.window-container {
}
.window-left-container {
  background-color: #ff0058;
  padding: 1rem;
  border-radius: 0.3rem;
}
.window-hour-txt {
  font-size: large;
  font-weight: bold;
  color: white;
}
.window-price-container {
}
.window-price-txt {
  font-size: small;
  font-weight: 500;
  color: white;
}
.window-right-container {
}
.window-address-container {
}
.window-address-txt {
  font-size: small;
  font-weight: bold;
  color: black;
}
.window-start-point {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 1.25vw;
  background-color: #47a716;
}
.window-end-point {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 1.25vw;
  background-color: red;
}
.pool-spinner-div {
  height: 54vh;
  background-color: #f5f5f5;
}
.pool-map {
  height: 55vh;
}

.queue-screen {
}
.queue-screen-header {
  background-color: black;
  height: 5vh;
  width: 100vw;
}
.queue-title-txt {
}
.queue-flat-list {
  height: 60vh;
}

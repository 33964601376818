.timer-container {
  position: absolute;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
}
.timer-txt {
  font-size: small;
  font-weight: 500;
}

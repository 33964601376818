.screen {
}
.black-header {
  background-color: black;
  height: 10vh;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}
.black-header-txt {
  color: white;
}
.white-underline {
  height: 0.4rem;
  width: 7rem;
  background-color: white;
}
.black-underline {
  height: 0.4rem;
  width: 7rem;
  background-color: black;
}
.info-txt-div {
  margin-top: 15vh;
}
.info-txt {
  font-size: 16px;
}
.pass-name-div {
  margin-top: 3vh;
}
.pass-name {
  font-size: 30px;
  font-weight: bold;
}
.phone-icon {
  width: 30px;
  height: 30px;
  margin-top: 3.5vh;
}
.take-pass-btn {
  margin-top: 10vh;
  width: 250px;
  height: 7vh;
  font-weight: bold;
}
.no-pass-btn {
  color: red;
  margin-top: 8vh;
}

.workcount {
  background-color: yellow;
}
.kmcount {
  background-color: orange;
}
.pointcount {
  background-color: #d9e4fc;
}
.earning-list {
  overflow: scroll;
  height: 45vh;
  margin-bottom: 15vh;
}
.payment-txt {
  font-size: x-small;
  margin-top: 1vh;
}

.screen {
}
.black-header {
  background-color: black;
  height: 10vh;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}
.black-header-txt {
  color: white;
}
.white-underline {
  height: 0.4rem;
  width: 7rem;
  background-color: white;
}
.black-underline {
  height: 0.4rem;
  width: 7rem;
  background-color: black;
}
.address-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.letter-txt {
  font-size: 10vw;
  font-weight: 600;
  color: grey;
  width: 10vw;
  text-align: end;
}
.address-txt {
  font-size: 3vw;
  font-weight: 500;
  width: 80vw;
}
.open-map-btn-div {
  padding-top: 0.2rem;
  padding-left: 10vw;
}
.open-map-btn {
  width: 30vw;
  height: 8vh;
  font-weight: bold;
}
.pick-up-btn-div {
  position: absolute;
  z-index: 99;
  margin-top: 50vh;
}
.btn.pick-up-btn {
  font-weight: bold;
}

.choose-car-screen {
  background-color: #f2f2f2;
  height: 100vh;
}
.cars-flat-list {
  overflow: scroll;
  height: 80vh;
}
.default-check {
  width: 5vw;
  height: 5vw;
  border-radius: 2.5vw;
}
.selected-check {
  width: 3vw;
  height: 3vw;
  border-radius: 1.5vw;
  background-color: green;
}
.render-car-list {
  border-color: lightgrey;
}
.car-icon-div {
  background-color: #f7d552;
  width: 12vw;
  height: 12vw;
  border-radius: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.render-plate-txt {
  font-size: large;
  font-weight: 600;
}
.render-brand-txt {
  font-size: smaller;
  color: silver;
  font-weight: 500;
  margin-top: 1vh;
}
.car-search-input {
  height: 6vh;
}
.select-car-alert {
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-car-alert-container {
  background-color: white;
  height: 20vh;
  width: 80vw;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

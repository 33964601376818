.screen {
}
.black-header {
  background-color: black;
  height: 10vh;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}
.black-header-txt {
  color: white;
}
.white-underline {
  height: 0.4rem;
  width: 7rem;
  background-color: white;
}
.black-underline {
  height: 0.4rem;
  width: 7rem;
  background-color: black;
}
.title-txt-div {
  margin-top: 25vh;
}
.title-txt {
  font-size: 25px;
  font-weight: bold;
}
.check-icon {
  width: 30px;
  height: 30px;
  margin-top: 5vh;
}
.completed-btn {
  margin-top: 15vh;
  width: 250px;
  height: 7vh;
  font-weight: bold;
}

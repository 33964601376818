.supervisor {
  background-color: #f7f7f7;
  height: 100vh;
}
.supervisor-header {
  background-color: black;
  width: 100vw;
  height: 5vh;
}
.supervisor-header-box {
  margin-top: 2vh;
}
.supervisor-header-container {
  width: 25vw;
}
.supervisor-header-txt {
  font-size: 3vw;
  font-weight: 700;
}
.supervisor-header-number {
  font-size: 4.5vw;
  font-weight: 500;
}
.supervisor-input-div {
}
.supervisor-input {
  background-color: #f7f7f7;
  width: 20vw;
  height: 10vh;
  color: black;
}
.supervisor-call-btn {
  width: 47vw;
  height: 7vh;
}
.supervisor-flat-div {
  overflow: scroll;
  height: 50vh;
}
.render-driver-left {
  background-color: #83bf65;
}
.render-driver-exit-left {
  background-color: white;
}
.render-driver-car-img {
  width: 14vw;
  height: 8vw;
}
.render-driver-name-txt {
  font-size: 4vw;
}
.render-driver-plate-txt {
  font-size: 3vw;
}
.infobox-container {
  position: absolute;
  z-index: 100;
  background-color: white;
}
.infobox-flat-div {
  height: 50vh;
  overflow: scroll;
}
.render-infobox-list {
}
.out-circle {
  width: 4vw;
  height: 4vw;
  border-radius: 2vw;
  background-color: white;
  justify-content: center;
  align-items: center;
}
.inner-circle {
  width: 2.4vw;
  height: 2.4vw;
  border-radius: 1.2vw;
  background-color: white;
}
.selected-inner-circle {
  width: 2.4vw;
  height: 2.4vw;
  border-radius: 1.2vw;
  background-color: green;
}
.penalty-txt {
  font-size: small;
  color: gray;
}

.agency-transfers-screen {
}
.agency-transfers-header {
  height: 15vh;
  background-color: black;
}
.agency-transfers-body {
  height: 72vh;
  background-color: #f5f5f5;
}
.time-buttons {
}
.time-button-view {
}
.time-button-txt {
}
.time-selected-button-line {
  background-color: white;
  height: 1vh;
  width: 20vw;
}
.time-button-line {
  background-color: black;
  height: 1vh;
  width: 20vw;
}
.agency-transfers-list {
  overflow: scroll;
  background-color: #f5f5f5;
  height: 60vh;
  margin-bottom: 15vh;
}

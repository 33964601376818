.gate-screen {
}
.gate-screen-header {
  background-color: black;
  height: 5vh;
  width: 100vw;
}
.gate-title-txt {
}
.gate-flat-list {
  height: 65vh;
}

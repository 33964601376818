.pool-bottom-bar-screen {
}
.pool-bottom-bar-container {
  width: 94vw;
  height: 12vh;
  border-radius: 2vw;
  background-color: #000000;
  margin-bottom: 3vh;
}
.pool-home-icon {
}
.pool-home-txt {
  color: white;
  font-size: smaller;
}
.pool-home-txt-selected {
  color: white;
  font-size: medium;
}
.pool-home-icon-selected {
}
.pool-pool-icon {
}
.pool-pool-icon-selected {
}
.pool-pool-txt {
  color: white;
  font-size: smaller;
}
.pool-pool-txt-selected {
  color: white;
  font-size: medium;
}
.pool-profile-icon {
}
.pool-proifle-icon-selected {
}
.pool-profile-txt {
  color: white;
  font-size: smaller;
}
.pool-profile-txt-selected {
  color: white;
  font-size: medium;
}
